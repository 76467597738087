import React, { useState, useCallback } from "react";
import ReactDOM from "react-dom";
import { graphql } from "gatsby";
import { FormattedMessage, Link, injectIntl } from "gatsby-plugin-intl";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import Grid from "styled-components-grid";
import BorderGrid, { BorderGridCol } from "../components/border-grid";
import { Slide } from "react-slideshow-image";
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

import BlockText from '../components/block-text'
import Container from "../components/container";
import SideBarLeft from "../components/sidebar-left";
import SideBarRight from "../components/sidebar-right";
import { buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'
import GraphQLErrorList from "../components/graphql-error-list";

import Content, { ContentDivider, ContentHeader, ContentImage } from "../components/content";
import { Heading2 } from "../components/heading";
import Mono, { MonoUppercase } from "../components/mono";
import RatioBox from "../components/ratio-box";
import SEO from "../components/seo";
import { FixedImage } from "../components/layout";
import header from "../components/header";

export const query = graphql`
  query SpeakerTemplateQuery($id: String!) {
    speaker: sanitySpeaker(id: { eq: $id }) {
      id
      slug {
        current
      }
      title {
        fi
        en
      }
      author
      color1
      color2
      publishedAt
      mainImage {
        _key
        _type
        caption
        alt
        hotspot {
          _key
          _type
          x
          y
          height
          width
        }
        asset {
          _id
          id
          url
          fixed {
            aspectRatio
            width
            height
            src
            srcSet
            srcWebp
            srcSetWebp
          }
          fluid {
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
        }
      }
      listingImage {
        _key
        _type
        caption
        alt
        hotspot {
          _key
          _type
          x
          y
          height
          width
        }
        asset {
          _id
          id
          url
          fixed {
            aspectRatio
            width
            height
            src
            srcSet
            srcWebp
            srcSetWebp
          }
          fluid {
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
        }
      }
      galleryImages {
        _key
        _type
        caption
        alt
        hotspot {
          _key
          _type
          x
          y
          height
          width
        }
        asset {
          _id
          id
          url
          fixed {
            aspectRatio
            width
            height
            src
            srcSet
            srcWebp
            srcSetWebp
          }
          fluid {
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
        }
      }
      _rawBody
      website
      instagram
      twitter
      country
      _rawEmbedCode
    }
  }
`;

const SpeakerTemplate = ({ intl, ...props }) => {
  const { data, errors } = props;
  const node = data && data.speaker;
  const locale = intl.locale || "en";
  var title = node.author;
  var twolineheader = false;
  if (node.author.includes('&')) {
    const title_parts = node.author.split('&');
    var title_parts_1 = title_parts[0];
    var title_parts_2 = title_parts[1];
    twolineheader = true;
  }


  var imgsrc = imageUrlFor(buildImageObj(node.mainImage));
  var imgsrcmobile = imageUrlFor(buildImageObj(node.listingImage));
  var headerClass = "";
  var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  if (isSafari) {
    headerClass = 'safari-speaker';
  }

  return (
    <React.Fragment>
      {errors && <SEO title="GraphQL Error" />}
      {node && <SEO title={node.author || "Untitled"} locale={locale} lang={locale} />}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}
      <Grid bottom={false}>
        <Grid.Unit size={{ xs: 1 / 1 }}>
          <StyledContent bgColor="orange" data-color="orange-background" fgColor="yellowish">
            <StyledContentHeader>
              
            {(!twolineheader) && (
              <AuthorHeading fgColor="yellowish">
                {title} 
              {node.country && (
                <StyledCountry className={headerClass}>
                  {node.country}
                </StyledCountry>
              )}
              </AuthorHeading>
              
              )}
              
              {(twolineheader) && (
                <AuthorHeading fgColor="yellowish">
                {title_parts_1} &<br/> {title_parts_2}
              {node.country && (
                <StyledCountry className={headerClass}>
                  {node.country}
                </StyledCountry>
              )}
              </AuthorHeading>
              )}
            </StyledContentHeader>
            <StyledImageContainer>
              <img src={imgsrc} class="speaker-img-desktop" />
              <img src={imgsrcmobile} class="speaker-img-mobile" />
            </StyledImageContainer>
            <StyledContentHeader>
                {(node.title && node.title[intl.locale]) && (
                  <StyledTitle>
                    {node.title[intl.locale]}
                  </StyledTitle>
                )}
                {(!node.title || !node.title[intl.locale]) && (
                  <Placeholder></Placeholder>
                )}
            </StyledContentHeader>
          </StyledContent>
          <StyledFlexContent bgColor="yellowish" data-color="yellowish-background" fgColor="orange">
            <SideBarLeft word={node.author} fill="var(--color-orange)"></SideBarLeft>
            <StyledPanelContentCenter id="center-scroller">
              <ContentWrapper>
                <StyledContentDivider />
                <MainContent>
                  <BlockText blocks={node._rawBody[intl.locale]} />
                </MainContent>
                <StyledContentDivider />
                {(node.website || node.instagram || node.twitter) && (
                  <StyledWebsiteLink>
                    {node.website && (
                      <a href={node.website} target="_blank" className="button-link">WWW</a>
                    )}
                    {node.instagram && (
                      <a href={node.instagram} target="_blank" className="button-link">Instagram</a>
                    )}
                    {node.twitter && (
                      <a href={node.twitter} target="_blank" className="button-link">Twitter</a>
                    )}
                  </StyledWebsiteLink>
                )}
                {(node.galleryImages && node.galleryImages.length > 1) && (
                  <React.Fragment>
                    <ShowOnMedium>
                      <Slideshow images={node.galleryImages}></Slideshow>
                    </ShowOnMedium>
                    <HideOnMedium>
                      <MobileImages images={node.galleryImages}></MobileImages>
                    </HideOnMedium>
                  </React.Fragment>
                )}
              </ContentWrapper>
            </StyledPanelContentCenter>
            <SideBarRight word={node.author} fill="var(--color-orange)"></SideBarRight>
          </StyledFlexContent>
        </Grid.Unit>
      </Grid>
      <StyledBackground />
    </React.Fragment>
  );
};

const StyledPanelContentCenter = styled.div`
  flex-basis: 100vw;
  flex-basis: 100vw;
  margin-bottom: 40px;
  ${breakpoint("md")`
    margin-bottom: 0;
  `}
`;

const StyledFlexContent = styled(Content)`
  overflow: hidden;
  display: flex;
  .default-nav {
    display: none;
  }
  padding: 15px 20px;
  ${breakpoint("md")`
    padding: 60px 5px;
  `}
`;

const StyledContentDivider = styled(ContentDivider)`
  width: 17%;
  margin: 40px 0;
  ${breakpoint("md")`
    margin: 20px 0;
  `}
`;

const StyledBackground = styled.div`
  position: fixed;
  z-index: -10;
  width: 100%;
  height: 100%;
`;

const Placeholder = styled.div`
  height: 64px;
  width: 100%;
`;


const StyledImage = styled.img`
  max-width: 80%;
  ${breakpoint("md")`
    margin: 20px auto;
    max-width: 600px;
  `}
`;

const StyledImageContainer = styled.div`
  text-align: center;
`;

const ShowOnMedium = styled.div`
  display: none;
  ${breakpoint("md")`
    display: block;
  `}
`;

const HideOnMedium = styled.div`
  display: block;
  img {
    max-width: 100%;
  }
  ${breakpoint("md")`
    display: none;
  `}
`;

const ContentWrapper = styled.div`
  max-width: 600px;
  margin: auto;
  p {
    font-size: 18px;
    line-height: 22px;
    ${breakpoint("md")`
    font-size: 25px;
    line-height: 30px;
  `}
  }
`;

const MainContent = styled.div`
  margin: 70px 0;
  h2 {
    font-family: var(--font-family-header);
    font-size: 40px;
    line-height: 35px;
    font-weight: normal;
    ${breakpoint("md")`
      font-size: 50px;
      line-height: 45px;
    `}
    strong {
      font-family: var(--font-family-header);
    }
  }
`;

const StyledContentImage = styled(ContentImage)`
  ${props => props.overlayColor && `
    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: var(--color-${props.overlayColor});
      mix-blend-mode: screen;
    }
  `}
`;

const StyledContent = styled(Content)`
  padding: 15px 20px;
  ${breakpoint("md")`
    padding: 160px 5px 60px 5px;
  `}
`;

const StyledTitle = styled.div`
  display: inline-block;
  font-size: 18px;
  line-height: 21px;
  font-family: var(--font-family-bold);
  letter-spacing: normal;
  padding: 3px 7px;
  margin: auto;
  text-transform: uppercase;
  border: 1px solid var(--color-yellowish);
  border-radius: 3px;  
  ${breakpoint("md")`
    margin-top: 35px;
`}
`;

const StyledCountry = styled.div`
  display: inline-block;
  font-size: 18px;
  font-family: var(--font-family);
  letter-spacing: normal;
  padding: 3px;
  border-radius: 3px;
  margin-left: 10px;
  position: absolute;
  ${breakpoint("md")`
    line-height: 233px;
  `}
`;

const StyledContentHeader = styled(ContentHeader)`
  margin: 20px 0;
  color: var(--color-white);
  padding: 0 12px;
  text-align: center;
  ${breakpoint("md")`
    margin-top: 0;
  `}
`;

const AuthorHeading = styled(Heading2)`
  font-family: var(--font-family-header);
  margin-bottom: 10px!important;
  text-transform: capitalize;
  font-weight: normal;
  font-size: 60px;
  line-height: 50px;
  letter-spacing: normal;
  position: relative;
  ${breakpoint("md")`
    font-size: 180px;
    line-height: 140px;
  `}
`;

const StyledRatioBox = styled.div`
  ${breakpoint("md")`
    display: none;
  `}
`;

const StyledWebsiteLink = styled.div`
  a {
    font-family: var(---font-family-bold);
    font-size: 16px;
    line-height: 22px;
    text-transform: uppercase;
    padding-bottom: 12px;
    &:after {
      content: '↗';
      font-size: 16px;
      font-family: var(--font-family-bold);
      font-weight: 700;
      margin-left: 5px;
    }
    ${breakpoint("md")`
    font-family: var(---font-family-bold);
    font-size: 18px;
    line-height: 21px;
    &:after {
      font-size: 18px;
    }
  `}
  }
`;

const StyledGalleryComponentWrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;

  ${breakpoint("md")`
    border-left: 7px solid var(--color-white);
  `}

  .react-photo-gallery--gallery {
    margin: 0 -4px 0 -2px;

    ${props => props.overlayColor && `
      &:after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: var(--color-${props.overlayColor});
        mix-blend-mode: screen;
        pointer-events: none;
      }
    `}

    img {
      filter: grayscale(100%);
      margin: 1px !important;
      display: block;
    }
  }
`;

const divStyle = {
  height: 'auto',
  width: '100%',
  marginTop: '50px'
}


const Slideshow = (props) => {
  return (
    <div className="slide-container" style={{maxHeight: '630px'}}>
      <Fade>
       {props.images.map((slideImage, index)=> (
          <div key={index}>
            <img style={{ ...divStyle }} src={slideImage.asset.url} />
          </div>
        ))} 
      </Fade>
    </div>
  )
}

const MobileImages = (props) => {
  return (
    <div className="mobile-images-container">
       {props.images.map((slideImage, index)=> (
            <StyledImageContainer>
              <StyledImage src={imageUrlFor(slideImage.asset.url)} />
            </StyledImageContainer>
        ))} 
    </div>
  )
}


const GalleryComponent = (props) => {
  const { node } = props;
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const gallery = [];

  const customStyles = {
    blanket: (base, state) => ({
      ...base,
      background: 'var(--color-yellow) !important'
    }),
    header: (base, state) => ({
      ...base,
      background: 'none !important'
    }),
    headerClose: (base, state) => ({
      ...base,
      color: 'white !important'
    }),
    navigationPrev: (base, state) => ({
      ...base,
      background: 'none !important'
    }),
    navigationNext: (base, state) => ({
      ...base,
      background: 'none !important'
    }),
    footer: (base, state) => ({
      ...base,
      background: 'none !important'
    }),
    footerCount: (base, state) => ({
      ...base,
      color: 'white !important',
      fontSize: '14px !important',
      textTransform: 'uppercase',
      letterSpacing: '0.03em'
    })
  }

  node.galleryImages.map((image, index) => {
    const imageUrl = imageUrlFor(buildImageObj(image))
      .width(1200)
      .maxWidth(1200)
      .maxHeight(1200)
      .auto('format')
      .quality(70)
      .url();

    const obj = {
      src: imageUrl,
      width: image.asset.fixed.width,
      height: image.asset.fixed.height
    }
    gallery.push(obj);
  });


  return (
    <React.Fragment>
      <Gallery photos={gallery} onClick={openLightbox} />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal allowFullscreen={false} onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              styles={customStyles}
              hideControlsWhenIdle={false}
              views={gallery.map(x => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </React.Fragment>
  );
};

export default injectIntl(SpeakerTemplate);
