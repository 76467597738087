import React, { Component } from "react";
import { graphql } from "gatsby";
import { Link, injectIntl } from "gatsby-plugin-intl";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import Obfuscate from "react-obfuscate";

import { buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'
import BlockText from '../components/block-text'

import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import BorderGrid, { BorderGridCol } from "../components/border-grid";
import Card from "../components/card";
import Content, { ContentDivider, ContentImage, ContentImageContainer } from "../components/content";
import { Heading2, Heading3 } from "../components/heading";
import SideBarLeft from "../components/sidebar-left";
import SideBarRight from "../components/sidebar-right";
import Mono from "../components/mono";
import RatioBox from "../components/ratio-box";
import Footer from "../components/footer";

export const query = graphql`
query InfoPageQuery {
    site: sanitySiteSettings(_id: {regex: "/(drafts.|)siteSettings/"}) {
      title
      _rawDescription
      keywords
    }
    infopage: sanityInfopage {
      id
      infopageImage {
        _key
        _type
        caption
        alt
        hotspot {
            _key
            _type
            x
            y
            height
            width
        }
        asset {
          _id
          id
          url
          fixed {
            aspectRatio
            width
            height
            src
            srcSet
            srcWebp
            srcSetWebp
          }
          fluid {
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
        }
      }
      _rawInfopageTitle
      _rawInfopageBody
      _rawTicketsTitle
      _rawTicketsBody
      _rawFootnotesTitle
      _rawFootnotes
      _rawContactTitle
      _rawContact
    }
  }
`;

const InfoPage = ({ intl, ...props }) => {
    const { data, errors } = props;
    const locale = intl.locale || "en";

    if (errors) {
        return (
            <Layout>
                <GraphQLErrorList errors={errors} />
            </Layout>
        );
    }

    const site = (data || {}).site;

    if (!site) {
        throw new Error(
            'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
        );
    }

    return (
        <React.Fragment>
            <SEO
                title={`${intl.messages.menu_info}`}
                description={site._rawDescription[locale]}
                keywords={site.keywords}
                lang={locale}
            />
            <StyledFlexContent data-color="yellowish-background">
                <SideBarLeft word={data.infopage._rawInfopageTitle[intl.locale]} fill="var(--color-orange)"></SideBarLeft>
                <StyledPanelContentCenter id="center-scroller">
                <StyledInfoContainer>
                    <BorderGrid>
                        <BorderGridCol size={{ sm: 1 / 1, md: 1 / 4 }}>
                            <StyledRightAlign>
                                <StyledContent>
                                    <StyledHeading2>{data.infopage._rawInfopageTitle[intl.locale]}</StyledHeading2>
                                </StyledContent>
                            </StyledRightAlign>
                        </BorderGridCol>
                        <BorderGridCol size={{ xs: 1 / 1, md: 3 / 4 }}>
                            <StyledContent>
                                <BlockText blocks={data.infopage._rawInfopageBody[intl.locale]} />
                            </StyledContent>
                        </BorderGridCol>
                        <BorderGridCol size={{ xs: 1 / 1, md: 1 / 4 }}>
                            <StyledRightAlign>
                                <StyledContent>
                                    <StyledHeading2>{data.infopage._rawTicketsTitle[intl.locale]}</StyledHeading2>
                                </StyledContent>
                            </StyledRightAlign>
                        </BorderGridCol>
                        <BorderGridCol size={{ xs: 1 / 1, md: 3 / 4 }}>
                            <StyledContent>
                                <BlockText blocks={data.infopage._rawTicketsBody[intl.locale]} />
                            </StyledContent>
                        </BorderGridCol>
                        <BorderGridCol size={{ xs: 1 / 1, md: 1 / 4 }}>
                            <StyledRightAlign>
                                <StyledContent>
                                    <StyledHeading2>{data.infopage._rawFootnotesTitle[intl.locale]}</StyledHeading2>
                                </StyledContent>
                            </StyledRightAlign>
                        </BorderGridCol>
                        <BorderGridCol size={{ xs: 1 / 1, md: 3 / 4 }}>
                            <StyledContent>
                                <BlockText blocks={data.infopage._rawFootnotes[intl.locale]} />
                            </StyledContent>
                        </BorderGridCol>
                        <BorderGridCol size={{ xs: 1 / 1, md: 1 / 4 }}>
                            <StyledRightAlign>
                                <StyledContent>
                                    <StyledHeading2>{data.infopage._rawContactTitle[intl.locale]}</StyledHeading2>
                                </StyledContent>
                            </StyledRightAlign>
                        </BorderGridCol>
                        <BorderGridCol size={{ xs: 1 / 1, md: 3 / 4 }}>
                            <StyledContent>
                                <BlockText blocks={data.infopage._rawContact[intl.locale]} />
                                <Obfuscate email="julia.valanne@grafia.fi" />
                            </StyledContent>
                        </BorderGridCol>
                    </BorderGrid>
                    </StyledInfoContainer>
                    </StyledPanelContentCenter>
                    <SideBarRight word={data.infopage._rawInfopageTitle[intl.locale]} fill="var(--color-orange)"></SideBarRight>
                </StyledFlexContent>
        </React.Fragment>
    );
};

const StyledRightAlign = styled.div`
    text-align: left;
    ${breakpoint("md")`
        text-align: right;
        padding-right: 30px;
    `}
`;

const StyledPanelContentCenter = styled.div`
    flex-basis: 100vw;
    flex-basis: 100vw;
`;

const StyledFlexContent = styled(Content)`
    overflow: hidden;
    display: flex;
    p {
        font-size: 18px;
        line-height: 22px;
    }
    ${breakpoint("md")`
        padding: 60px 5px;
        p {
            font-size: 25px;
            line-height: 30px;
        }
    `}
`;

const StyledContent = styled(Content)`
    padding: 15px 20px;
    ${breakpoint("md")`
        padding: 40px 20px;
    `}
`;

const StyledInfoContainer = styled.div`
    strong {
        text-transform: none;
        letter-spacing: normal;
    }
    ${breakpoint("md")`
        margin: 50px auto;
        max-width: 70%;
    `}
`;

const StyledHeading2 = styled(Heading2)`
    font-family: var(--font-family-bold);
    text-align: left;
    margin-bottom: 0!important;
    ${breakpoint("md")`
        font-size: 18px;
        line-height: 28px;
        text-align: right;
    `}
`;

export default injectIntl(InfoPage);
