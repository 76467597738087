import React, { Component } from "react";
import { graphql } from "gatsby";
import { Link, injectIntl } from "gatsby-plugin-intl";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import {Margin} from "styled-components-spacing";
import Obfuscate from "react-obfuscate";

import { buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'
import BlockText from '../components/block-text'

import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Content, { ContentDivider, ContentImage, ContentImageContainer } from "../components/content";
import SideBarLeft from "../components/sidebar-left";
import SideBarRight from "../components/sidebar-right";
import { Heading2, Heading3 } from "../components/heading";
import Table, { Tr, Td  } from "../components/table";

export const query = graphql`
query TimetablePageQuery {
  site: sanitySiteSettings(_id: {regex: "/(drafts.|)siteSettings/"}) {
    title
    _rawDescription
    keywords
  }
  timetablepage: sanityTimetable {
    _id
    title {
      fi
      en
    }
    _rawTimetableBody
    _rawTimetableBodyLocalized
    pdf {
      _key
      asset {
        url
      }
    }
  }
}
`;

const TimetablePage = ({ intl, ...props }) => {
  const { data, errors } = props;
  const locale = intl.locale || "en";

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }
  
  const site = (data || {}).site;

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  return (
    <React.Fragment>
      <SEO
        title={`${intl.messages.menu_schedule}`}
        description={site._rawDescription[locale]}
        keywords={site.keywords}
        lang={locale}
      />
      <StyledFlexContent data-color="yellowish-background">
        <SideBarLeft word={data.timetablepage.title[intl.locale]} fill="var(--color-orange)"></SideBarLeft>
          <StyledPanelContentCenter id="center-scroller">
            <Content>
              <StyledMono>
                {data.timetablepage.pdf && data.timetablepage.pdf.asset && (
                  <StyledPdf>
                    <StyledPdfLink href={data.timetablepage.pdf.asset.url}>
                      Lataa PDF / Download PDF
                    </StyledPdfLink>
                  </StyledPdf>
                )}
                {data.timetablepage._rawTimetableBodyLocalized && data.timetablepage._rawTimetableBodyLocalized[locale] && (
                  <BlockText blocks={data.timetablepage._rawTimetableBodyLocalized[locale]} />
                )}
              </StyledMono>
            </Content>
          </StyledPanelContentCenter>
          <SideBarRight word={data.timetablepage.title[intl.locale]} fill="var(--color-orange)"></SideBarRight>
      </StyledFlexContent>
    </React.Fragment>
  );
};

const StyledTable = styled(Table)`
  margin: 50px auto;
  max-width: 666px;
  text-transform: uppercase;
`;

const StyledPdf = styled.div`
  padding-top: 1em;
  margin-bottom: 3.5em;
`;

const StyledPdfLink = styled.a`
  display: inline-block;
  color: var(--color-black) !important;
  border-bottom: 2px solid var(--color-black) !important;
`;

const StyledPanelContentCenter = styled.div`
  flex-basis: 100vw;
  flex-basis: 100vw;
`;

const StyledFlexContent = styled(Content)`
  padding: 60px 5px;
  overflow: hidden;
  display: flex;
`;

const StyledMono = styled.div`
  p {
    margin-bottom: 2em;
  }
  h2 {
    border-bottom: 1px solid var(--color-orange);
    margin: 20px 0;
    padding-bottom: 15px;
    display: flex;
    justify-content: space-between;
    font-weight: normal;
    font-family: var(--font-family-header);
    font-size: 40px;
    line-height: 25px;
    ${breakpoint("lg")`
      font-size: 60px;
      line-height: 49px;
      margin: 30px 0;
      padding-bottom: 15px;
    `}
    a {
      text-align: right;
      font-size: 28px;
      line-height: 25px;
      padding-left: 3px;
      ${breakpoint("lg")`
        font-size: 60px;
        line-height: 49px;
      `}
    }
    strong {
      text-align: right;
      font-weight: normal;
      font-family: var(--font-family-header);
      text-transform: capitalize;
      font-size: 28px;
      line-height: 25px;
      letter-spacing: normal;
      ${breakpoint("lg")`
        font-size: 60px;
        line-height: 49px;
      `}
    }
    em {
      text-align: right;
      font-style: normal;
      font-size: 28px;
      line-height: 25px;
      text-transform: uppercase;
      ${breakpoint("lg")`
        font-size: 40px;
        line-height: 49px;
      `}
    }
    code {
      display: inline-block;
      font-size: 14px;
      line-height: 16px;
      font-family: var(--font-family-bold);
      letter-spacing: normal;
      border: 1px solid var(--color-orange);
      padding: 3px 8px;
      border-radius: 3px;
      text-transform: uppercase;
      vertical-align: middle;
      margin-left: -20px;
      ${breakpoint("lg")`
        font-size: 18px;
        line-height: 21px;
      `}
    }
  }
`;

export default injectIntl(TimetablePage);
