import { Link as GatsbyLink } from "gatsby";
import React, { Component } from "react";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import { injectIntl, Link } from "gatsby-plugin-intl";
import SVG from "react-inlinesvg";

import { cn } from "../lib/helpers";
import styles from "./header.module.css";

import GrafiaLogo from "../images/grafia.svg";
import Logo from "src/images/huiput-1.svg";
import LogoWhite from "src/images/huiput-white2.svg";
import MenuClose from "src/images/menu-close.svg";
import MenuBurger from "src/images/menu-burger.svg";
import MenuBurgerWhite from "src/images/menu-burger-white.svg";

import HdwLogo from "../images/logo/hdw.svg";
import HdWeeklyLogo from "../images/logo/hdweekly.svg";
import MarkprintLogo from "../images/logo/markprint_2023.svg";
import LsbLogo from "../images/logo/lsb.svg";
import OutdoorFinlandLogo from "../images/logo/outdoorfinland-new.svg";
import DeedCreativeLogo from "../images/logo/deed-creative.svg";
import ALehdetLogo from "../images/logo/a-lehdet.svg";
import EeroAarnioLogo from "../images/logo/eeroaarnio.svg";
import PagaLogo from "../images/logo/paga.svg";
import Basta from "../images/logo/basta.svg";

const StyledHeader = styled.div`
  padding: 17px 23px;
  font-size: 20px;

  ${breakpoint("lg")`
  `}
`;

const StyledHeaderContainer = styled.div`
  ${breakpoint("lg")`
  `}
`;

const StyledLogo = styled.div`
  a {
    display: block;
    color: var(--color-orange);
  }

  img {
    width: auto;
    height: 27px;
  }
  display: none;
  vertical-align: top;

  ${breakpoint("md")`
    display: inline-block;
  `}
`;

const StyledMenuClose = styled.button`
  position: absolute;
  top: 20px;
  right: 17.5px;
  z-index: 9999999;
  cursor: pointer;
  ${breakpoint("md")`
    display: block;
  `}
`;

const StyledNavigation = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: auto;
    text-align: right;
    background: var(--color-orange);
    z-index: 999;
    color: var(--color-yellowish);
    animation-name: slideup;
    animation-duration: 0.7s;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
    transform: translate(0, -20px);
    &.true {
      animation-name: slide;
    }
`;

const StyledNavigationContent = styled.div`
  display: inline-block;
  margin-right: 22%;
  text-align: left;
  margin-bottom: 10px;
  margin-top: 150px;
  a {
    color: inherit;
    text-decoration: none;
    font-size: 30px;
    line-height: 34px;
    font-weight: normal;
    ${breakpoint("md")`
      font-size: 40px;
      line-height: 46px;
    `}
  }
`;

const StyledLanguage = styled.ul`
  list-style: none;
  position: absolute;
  top: 20px;
  right: 150px;
  display: inline-block;
  z-index: 999999;
`;

const StyledLanguageItem = styled.li`
  display: inline-block;
  padding: 0;
  margin: 0;
  color: var(--color-yellowish);
  a {
    color: inherit;
    text-decoration: none;
    font-weight: bold;
    font-size: 18px;
  }
`;

const StyledLanguageMobile = styled.ul`
  list-style: none;
  display: block;
  margin-bottom: 50px!important;
  ${breakpoint("md")`
    display: none;
  `}
`;

const StyledMobileLogoAndDate = styled.div`
  display: flex;
  align-items: center;
  ${breakpoint("md")`
    display: none;
  `}
`;

const StyledMobileMenuLogo = styled.div`
  padding: 0;
  color: var(--color-blue);
  a {
    display: block;
  }
  a, svg {
    width: 100%;
    max-width: 102px;
    height: 25px;
  }
`;

const StyledMobileDate = styled.div`
  margin-left: 1rem;
  ${breakpoint("md")`
    margin-left: 1.5rem;
  `}
`;

const StyledMenu = styled.ul`
  list-style: none;
  margin: 0;
  padding: 10px 0;
`;

const StyledMenuItem = styled.li`
  padding: 0;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 0.06em;
`;


const StyledEventInfo = styled.div`
  display: none;
  ${breakpoint("md")`
    display: inline-block;
    font-size: 16px;
    margin-left: 20px;
    text-transform: uppercase;
    line-height: 1;
  `}
`;

const StyledEventInfoMenu = styled.div`
  display: inline-block;
  font-size: 16px;
  margin-left: 20px;
  text-transform: uppercase;
  line-height: 1;
  color: var(--color-yellowish);
  position: absolute;
  z-index: 9999999;
  top: 17px;
  left: 0;
  a {
    color: var(--color-yellowish);
  }
`;

const StyledHeaderRight = styled.div`
  position: absolute;
  top: 13px;
  right: 10px;
  ${breakpoint("md")`
    display: inline-block;
  `}
`;

const StyledTicketsLink = styled.div`
  display: inline-block;
  margin-right: 25px;
  padding: 7px 10px;
  font-size: 16px;
  text-transform: uppercase;
  line-height: 1;
  font-family: var(--font-family-bold);
  background-color: var(--color-orange);
  vertical-align: top;
  border-radius: 2px;
  margin-top: 2px;
  a {
    color: var(--color-yellowish);
  }
  ${breakpoint("md")`
    font-size: 18px;
  `}
`;

const StyledMenuBurger = styled.button`
  color: var(--color-orange);
  font-size: 22px;
  vertical-align: middle;
  z-index: 99999;
  cursor: pointer;
  display: none;
  margin-right: 5px;
  font-family: var(--font-family);
  ${breakpoint("md")`
    display: inline-block;
  `}
`;

const StyledMenuBurgerMobile = styled.button`
  margin-top: 5px;
  ${breakpoint("md")`
    display: none;
  `}
`;

const StyledGiantLogo = styled.div`
  width: 98%;
  height: auto;
  margin: auto;
  text-align: center;
  ${breakpoint("sm")`
    margin-top: 10px;
    margin-right: 2%;
    margin-bottom: 10px;
  `}
  img {
    width: 96%;
    height: auto;
    position: absolute;
    bottom: 14%;
    left: 2%;
    ${breakpoint("md")`
      position: relative;
    `}
  }
`;

const StyledHdWeeklyLogo = styled(SVG)`
    max-height: 60px !important;

    ${breakpoint("lg")`
        max-height: 70px !important;
    `}
`;
const StyledMarkprintLogo = styled(SVG)`
    max-height: 25px !important;

    ${breakpoint("lg")`
        max-height: 32px !important;
    `}
`;
const StyledLsbLogo = styled(SVG)`
    max-height: 50px !important;

    ${breakpoint("lg")`
        max-height: 70px !important;
    `}
`;
const StyledPagaLogo = styled(SVG)`
    max-height: 100px !important;

    ${breakpoint("lg")`
        max-height: 150px !important;
    `}
`;

const StyledOutdoorFinlandLogo = styled(SVG)`
    max-height: 100px !important;

    ${breakpoint("lg")`
        max-height: 180px !important;
    `}
`;
const StyledDeedCreativeLogo = styled(SVG)`
    max-height: 80px !important;

    ${breakpoint("lg")`
        max-height: 140px !important;
    `}
`;

const StyledBastaLogo = styled(SVG)`
    max-height: 30px !important;

    ${breakpoint("lg")`
        max-height: 40px !important;
    `}
`;

const StyledALehdetLogo = styled(SVG)`
    max-height: 80px !important;

    ${breakpoint("lg")`
        max-height: 140px !important;
    `}
`;

const StyledEeroAarnioLogo = styled(SVG)`
    max-height: 100px !important;

    ${breakpoint("lg")`
        max-height: 120px !important;
    `}
`;

const StyledGrafiaLogo = styled.div`
    width: 80px;
    margin: 20px auto;

    svg {
        width: 100%;
        height: auto;
    }

    ${breakpoint("lg")`
        width: 100px;
        margin: 0 auto;
    `}
`;

const StyledLogoGrid = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    ${breakpoint("lg")`
        width: 100%;
        flex-wrap: nowrap;
        max-height: 110px;
    `}
`;

const StyledLogoGridCol = styled.div`
    padding: 0 20px;
    text-align: center;
    flex-basis: calc(100% / 3);

    ${breakpoint("md")`
        flex-basis: calc(100% / 4);

        &:first-child {
            padding-left: 0;
        }
        &:last-child {
            padding-right: 0;
        }
    `}

    ${breakpoint("xl")`
        flex-basis: calc(100% / 8);
    `}

    a {
        display: block;
        margin: 0 auto;
    }

    img {
        width: auto;
        max-width: 120px;
        height: auto;
        max-height: 30px;
        margin: 20px auto;

        ${breakpoint("lg")`
            width: auto;
            max-width: 240px;
            height: auto;
            max-height: 55px;
            margin: 0;
        `}
    }
    svg {
        width: auto;
        max-width: 120px;
        height: auto;
        max-height: 40px;
        margin: 20px auto;
        fill: #FFF;

        ${breakpoint("lg")`
            width: 100%;
            max-width: 240px;
            height: auto;
            max-height: 55px;
            margin: 0px;
        `}
    }
`;

const StyledDivider = styled.div`
    height: 0;
    width: 100%;
    margin-bottom: 10px;
    border-bottom: 1px solid var(--color-yellowish);
`;

const StyledHeading = styled.p`
    padding-left: 40px;
    text-align: left;
    line-height: 1;
`;

const StyledUppercase = styled.div`
    text-transform: uppercase;
`;

const Desktop = styled.div`
    display: none;
    ${breakpoint("md")`
      display: block;
  `}
`;

const PreviousFestivals = styled.div`
  text-align: left;
  font-family: var(--font-family);
  text-transform: uppercase;
  margin: 20px 50px;
    a {
      color: var(--color-yellowish);
    }
`;

const StyledHdwLogo = styled(SVG)`
    max-height: 50px !important;

    ${breakpoint("lg")`
        max-height: 60px !important;
    `}
`;

const Header = ({ intl, onHideNav, onShowNav, showNav, siteTitle }) => {
  const isProgrammePage = React.useMemo(() => {
    const server = window.location.origin;
    const pageId = window.location.href.replace(server, '');
    return pageId != '/fi/programme/' && pageId != '/en/programme/' && pageId != '/fi/programme' && pageId != '/en/programme' && window.location.href.includes('programme');
  }, [window.location.href]);

  const [styleState, setStyleState] = React.useState({ 
    bgClass: isProgrammePage ? 'orange-background' : 'yellowish-background',
    buyTicketsClass: isProgrammePage ? 'orange-background-link' : 'yellowish-background-link',
    burgerSrc: isProgrammePage ? MenuBurger : MenuBurgerWhite
  });

  const setStyleStates = (dataColorAttributeValue) => {
    const isWhite = dataColorAttributeValue === 'yellowish-background';

    return setStyleState({
      bgClass: dataColorAttributeValue,
      buyTicketsClass: isWhite ? 'yellowish-background-link' : 'orange-background-link',
      burgerSrc: isWhite ? MenuBurger : MenuBurgerWhite
    });
  }

  const handleScroll = () => {
    const elements = Array.from(document.querySelectorAll('[data-color]'));

    elements.forEach((elem) => {
      const { top, bottom } = elem.getBoundingClientRect();
      
      if (top <= 0 && bottom > 0) {
        const dataColorAttributeValue = elem.getAttribute('data-color');
        
        setStyleStates(dataColorAttributeValue);
        return;
      }
    });    
  }

  React.useEffect(() => {
    const interval = setInterval(() => {
      const scrollableElement = document.querySelector('#scrollable');
      const elementWithBgColor = document.querySelector('[data-color]');
      if (scrollableElement && elementWithBgColor) {
        clearInterval(interval);
        scrollableElement.addEventListener('scroll', handleScroll);
        setStyleStates(elementWithBgColor.getAttribute('data-color'));
      }
    }, 100);
  }, [window.location.href]);

  const closeMenu = (e) => {
    const button = document.querySelector(".menu-close");

    if (button) {
      button.click();
    }
  }

  return (
    <StyledHeader id={styleState.bgClass}>
      <StyledHeaderContainer>
        <StyledLogo>
          <Link to="/">
            <SVG src={Logo} />
          </Link>
        </StyledLogo>
        <StyledEventInfo>
          {intl.messages.title}<br />
          {intl.messages.date} {intl.messages.location_1} {intl.messages.location_2}
        </StyledEventInfo>
        <StyledMobileLogoAndDate>
          <StyledMobileMenuLogo>
            <Link to="/">
              <SVG src={Logo} />
            </Link>
          </StyledMobileMenuLogo>
        </StyledMobileLogoAndDate>
        <StyledHeaderRight>
          <StyledTicketsLink id={styleState.buyTicketsClass}>
            <GatsbyLink target="_blank" to="https://www.grafia.fi/kauppa/">
              {intl.messages.menu_tickets}
            </GatsbyLink>
          </StyledTicketsLink>
          <StyledMenuBurger
            className={styles.toggleNavButton}
            onClick={showNav ? onHideNav : onShowNav}
          >
            MENU
          </StyledMenuBurger>
          <StyledMenuBurgerMobile
            className={styles.toggleNavButton}
            onClick={showNav ? onHideNav : onShowNav}
          >
            <SVG src={styleState.burgerSrc} />
          </StyledMenuBurgerMobile>
        </StyledHeaderRight>
        <nav className={cn(styles.nav, showNav && styles.showNav)}>
          <StyledEventInfoMenu>
            <Link to="/" onClick={(e) => closeMenu(e)}>
            {intl.messages.title}<br />
            {intl.messages.date} {intl.messages.location_1} {intl.messages.location_2}
            </Link>
          </StyledEventInfoMenu>
          <StyledMenuClose
            className={cn("menu-close", styles.toggleNavButton)}
            onClick={showNav ? onHideNav : onShowNav}
          >
            <img src={MenuClose} alt="Close" />
          </StyledMenuClose>
          <Desktop>
            <StyledLanguage>
              <StyledLanguageItem>
                <GatsbyLink to="/en/" className="en">
                  EN
                </GatsbyLink>
                &nbsp;/&nbsp;
              </StyledLanguageItem>
              <StyledLanguageItem>
                <GatsbyLink to="/fi/" className="fi">
                  FI
                </GatsbyLink>
              </StyledLanguageItem>
            </StyledLanguage>
          </Desktop>
          <StyledNavigation className={showNav}>
            <StyledNavigationContent>
              <MenuComponent intl={intl} siteTitle={siteTitle} component={StyledMenu} itemComponent={StyledMenuItem} />
              <StyledLanguageMobile>
                <StyledLanguageItem>
                  <GatsbyLink to="/en/" className="en">
                    EN
                  </GatsbyLink>
                  &nbsp;/&nbsp;
                </StyledLanguageItem>
                <StyledLanguageItem>
                  <GatsbyLink to="/fi/" className="fi">
                    FI
                  </GatsbyLink>
                </StyledLanguageItem>
              </StyledLanguageMobile>
            </StyledNavigationContent>
            <StyledGiantLogo>
              <Link to="/" onClick={(e) => closeMenu(e)}>
                <img src={LogoWhite} alt="Huiput" />
              </Link>
            </StyledGiantLogo>
            <Desktop>
              <StyledDivider />
              <StyledLogoGrid>
                <StyledLogoGridCol>
                  <StyledGrafiaLogo>
                      <a href="https://grafia.fi/" title="Grafia" target="_blank">
                          <SVG src={GrafiaLogo} />
                      </a>
                  </StyledGrafiaLogo>
                </StyledLogoGridCol>
                <StyledLogoGridCol>
                    <a href="https://www.helsinkidesignweek.com/" title="Helsinki Design Week" target="_blank">
                        <StyledHdwLogo src={HdwLogo} type="image/svg+xml" />
                    </a>
                </StyledLogoGridCol>
                <StyledLogoGridCol>
                    <a href="https://www.helsinkidesignweekly.com/" title="Helsinki Design Weekly" target="_blank">
                        <StyledHdWeeklyLogo src={HdWeeklyLogo} type="image/svg+xml" />
                    </a>
                </StyledLogoGridCol>
                <StyledLogoGridCol>
                    <a href="https://suomenulkomainosliitto.fi/" title="Outdoor Finland" target="_blank">
                        <StyledOutdoorFinlandLogo src={OutdoorFinlandLogo} type="image/svg+xml" />
                    </a>
                </StyledLogoGridCol>
                <StyledLogoGridCol>
                    <a href="https://www.markprint.fi/" title="Markprint" target="_blank">
                        <StyledMarkprintLogo src={MarkprintLogo} type="image/svg+xml" />
                    </a>
                </StyledLogoGridCol>
                <StyledLogoGridCol>
                    <a href="https://deedcreative.com/" title="Deed Creative" target="_blank">
                        <StyledDeedCreativeLogo src={DeedCreativeLogo} type="image/svg+xml" />
                    </a>
                </StyledLogoGridCol>
              </StyledLogoGrid>
              <StyledDivider />
              <PreviousFestivals>
                {intl.messages.previous_festivals}&nbsp;&nbsp; <a href="https://www.huiputfestival.fi/2020/" className="link">2020</a> | <a href="https://www.huiputfestival.fi/2021/" className="link">2021</a> | <a href="https://www.huiputfestival.fi/2022/" className="link">2022</a> | <a href="https://www.huiputfestival.fi/2023/" className="link">2023</a> | <a href="https://www.huiputfestival.fi/2024/" className="link">2024</a>
              </PreviousFestivals>
            </Desktop>
          </StyledNavigation>
        </nav>
      </StyledHeaderContainer>
    </StyledHeader>
  );
};

const MenuComponent = props => {
  const Component = props.component;
  const ItemComponent = props.itemComponent;
  const intl = props.intl;
  const closeMenu = (e) => {
    const button = document.querySelector(".menu-close");

    if (button && window !== "undefined") {
      if (window.getComputedStyle(button).display === "block") {
        button.click();
      }
    }
  }

  return (
    <Component>
      <ItemComponent>
        <Link to="/programme/" className={props.class} id="programme-link" onClick={(e) => closeMenu(e)}>
          {intl.messages.menu_programme}
        </Link>
      </ItemComponent>
      <ItemComponent>
        <Link to="/info/" className={props.class} onClick={(e) => closeMenu(e)}>
          {intl.messages.menu_info}
        </Link>
      </ItemComponent>
      <ItemComponent>
        <a href="https://www.grafia.fi/kauppa/" className={props.class} target="_blank">
          {intl.messages.menu_tickets}
        </a>
      </ItemComponent>
      <ItemComponent>
        <a href="https://www.instagram.com/huiputfestival/" className={props.class} target="_blank">
          Instagram
        </a>
      </ItemComponent>
    </Component>
  );
};

export default injectIntl(Header);
