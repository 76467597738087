import React from "react";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import { injectIntl, Link } from "gatsby-plugin-intl";
import SVG from "react-inlinesvg";

// import { BoldCopy, BoldCopyCss } from "./content";

import GrafiaLogo from "../images/grafia.svg";
import HdWeeklyLogo from "../images/logo/hdweekly.svg";
import MarkprintLogo from "../images/logo/markprint_2023.svg";
import LsbLogo from "../images/logo/lsb.svg";
import OutdoorFinlandLogo from "../images/logo/outdoorfinland-new.svg";
import DeedCreativeLogo from "../images/logo/deed-creative.svg";
import ALehdetLogo from "../images/logo/a-lehdet.svg";
import EeroAarnioLogo from "../images/logo/eeroaarnio.svg";
import PagaLogo from "../images/logo/paga.svg";
import HdwLogo from "../images/logo/hdw.svg";
import Content from "./content";
import Basta from "../images/logo/basta.svg";

const Footer = ({ intl }) => {
    var server = window.location.origin;
    var currentPage = window.location.href.replace(server, '');
    
    if (currentPage != '/fi/' && currentPage != '/en/' && currentPage != '/fi' && currentPage != '/en' && currentPage != '/fi/info/' && currentPage != '/en/info/' && currentPage != '/fi/info' && currentPage != '/en/info' ) {
        var footerClass = 'hide-footer';
    } else {
        var footerClass = 'show-footer';
    }
    // console.log(intl)
    /* Logot talteen
    <StyledLogoGridCol>
        <a href="https://www.a-lehdet.fi/" title="A-lehdet" target="_blank">
            <StyledALehdetLogo src={ALehdetLogo} type="image/svg+xml" />
        </a>
    </StyledLogoGridCol>
    <StyledLogoGridCol>
        <a href="https://deedcreative.com/" title="DEED Creative" target="_blank">
            <StyledDeedCreativeLogo src={DeedCreativeLogo} type="image/svg+xml" />
        </a>
    </StyledLogoGridCol>
    <StyledLogoGridCol>
        <a href="https://fi.aarniooriginals.com/" title="Eero Aarnio Originals" target="_blank">
            <StyledEeroAarnioLogo src={EeroAarnioLogo} type="image/svg+xml" />
        </a>
    </StyledLogoGridCol>              
    <StyledLogoGridCol>
        <a href="https://www.lsb.fi/" title="LSB" target="_blank">
            <StyledLsbLogo src={LsbLogo} type="image/svg+xml" />
        </a>
    </StyledLogoGridCol>
    */
    return (
        <StyledFooter className={footerClass} data-color="orange-background">
            <StyledTop>
                <StyledTopCol>
                    <StyledUppercase as="p">
                        ©2025 Huiput Creative Festival
                    </StyledUppercase>
                </StyledTopCol>
                <StyledTopCol>
                    <StyledGrafiaLogo>
                        <a href="https://grafia.fi/" title="Grafia" target="_blank">
                            <SVG src={GrafiaLogo} />
                        </a>
                    </StyledGrafiaLogo>
                </StyledTopCol>
                <StyledTopCol>
                    <StyledAlignRight>
                        <StyledUppercase as="p">
                            {intl.messages.date} {intl.messages.location_1} {intl.messages.location_2}
                        </StyledUppercase>
                    </StyledAlignRight>
                </StyledTopCol>
            </StyledTop>
            <StyledMobile>
                <StyledUppercase as="p">{intl.messages.partners}</StyledUppercase>
            <StyledLogoGrid>
                <StyledLogoGridCol>
                <StyledGrafiaLogo>
                    <a href="https://grafia.fi/" title="Grafia" target="_blank">
                        <SVG src={GrafiaLogo} />
                    </a>
                </StyledGrafiaLogo>
              </StyledLogoGridCol>
              <StyledLogoGridCol>
                    <a href="https://www.helsinkidesignweek.com/" title="Helsinki Design Week" target="_blank">
                        <StyledHdwLogo src={HdwLogo} type="image/svg+xml" />
                    </a>
                </StyledLogoGridCol>
              <StyledLogoGridCol>
                  <a href="https://www.helsinkidesignweekly.com/" title="Helsinki Design Weekly" target="_blank">
                      <StyledHdWeeklyLogo src={HdWeeklyLogo} type="image/svg+xml" />
                  </a>
              </StyledLogoGridCol>
              <StyledLogoGridCol>
                  <a href="https://suomenulkomainosliitto.fi/" title="Outdoor Finland" target="_blank">
                      <StyledOutdoorFinlandLogo src={OutdoorFinlandLogo} type="image/svg+xml" />
                  </a>
              </StyledLogoGridCol>
              <StyledLogoGridCol>
                  <a href="https://www.markprint.fi/" title="Markprint" target="_blank">
                      <StyledMarkprintLogo src={MarkprintLogo} type="image/svg+xml" />
                  </a>
              </StyledLogoGridCol>
                <StyledLogoGridCol>
                    <a href="https://deedcreative.com/" title="Deed Creative" target="_blank">
                        <StyledDeedCreativeLogo src={DeedCreativeLogo} type="image/svg+xml" />
                    </a>
                </StyledLogoGridCol>
            </StyledLogoGrid>
            <StyledDivider />
            <PreviousFestivals>
              {intl.messages.previous_festivals}<br /> <a href="https://www.huiputfestival.fi/2020/" className="link">2020</a> | <a href="https://www.huiputfestival.fi/2021/" className="link">2021</a> | <a href="https://www.huiputfestival.fi/2022/" className="link">2022</a> | <a href="https://www.huiputfestival.fi/2023/" className="link">2023</a>
            </PreviousFestivals>
            </StyledMobile>
        </StyledFooter>
    );
};

/*
const StyledGrafiaLogo = styled.div`
    width: 100%;
    margin: 20px 0;

    svg {
        width: 100px;
        height: auto;
    }

    ${breakpoint("md")`
        text-align: center;
        margin: 0;
        svg {
            width: 80px;
        }
    `}
`;*/

const StyledUppercase = styled.div`
    text-transform: uppercase;
`;

const StyledMobile = styled.div`
    color: var(--color-yellowish);
    background-color: var(--color-orange);
    p {
        text-align: center;
        padding: 30px 0;
    }
    ${breakpoint("md")`
        display: none;
    `}
`;

const StyledFooter = styled.div`
    color: var(--color-orange);
    background-color: var(--color-yellowish);
    letter-spacing: 0.06em;
    overflow: hidden;
    border-top: 1px solid var(--color-yellowish);
    font-size: 16px;

    &.hide-footer {
        display: none;
        ${breakpoint("md")`
            display: block;
        `}
    }

    ${breakpoint("md")`
        padding: 10px 25px;
        border-top: 1px solid var(--color-orange);
    `}

    p {
        margin-block-start: 0;
        margin-block-end: 0;
    }
    a {
        color: inherit;
        &:hover {
            &:after {
                background-color: var(--color-orange);
            }
        }
    }


    *:focus {
        outline: none;
        border: 0 solid transparent;
        border-radius: 3px;
        box-shadow: 0 0 0 3px var(--color-orange);
    }


    // inline svg colors
    svg {
        * {
            color: var(--color-yellowish);
        }
    }
`;

const StyledTop = styled.div`
    display: none;
    ${breakpoint("md")`
        margin-bottom: 10px;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    `}
`;

const StyledTopCol = styled.div`
   p {
       padding-bottom: 0;
   }

    ${breakpoint("md")`
        flex-basis: 33%;

        p {
            padding-bottom: 0;
        }
    `}
`;


const StyledLogoGrid = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    ${breakpoint("lg")`
        width: 100%;
        flex-wrap: nowrap;
    `}
`;

const StyledLogoGridCol = styled.div`
    padding: 0 20px;
    text-align: center;
    flex-basis: calc(100% / 3);

    ${breakpoint("md")`
        flex-basis: calc(100% / 7);
    `}

    ${breakpoint("md")`
        flex-basis: calc(100% / 7);

        &:first-child {
            padding-left: 0;
        }
        &:last-child {
            padding-right: 0;
        }
    `}

    ${breakpoint("xl")`
        flex-basis: calc(100% / 8);
    `}



    a {
        display: block;
        margin: 0 auto;
    }

    img {
        width: auto;
        max-width: 120px;
        height: auto;
        max-height: 30px;
        margin: 0 auto;

        ${breakpoint("lg")`
            width: auto;
            max-width: 240px;
            height: auto;
            max-height: 55px;
            margin: 0;
        `}
    }
    svg {
        width: auto;
        max-width: 120px;
        height: auto;
        max-height: 40px;
        margin: 10px auto;
        fill: #FFF;
        ${breakpoint("lg")`
            fill: var(--color-orange);
            width: 100%;
            max-width: 240px;
            height: auto;
            max-height: 55px;
            margin: 0px;
        `}
    }
`;

const StyledDivider = styled.div`
    height: 0;
    width: 100%;
    margin-bottom: 10px;
    border-bottom: 1px solid var(--color-yellowish);
`;

const PreviousFestivals = styled.div`
  text-align: center;
  font-family: var(--font-family);
  text-transform: uppercase;
  margin: 30px 50px;
  padding-bottom: 30px;
    a {
      color: var(--color-yellowish);
    }
`;

const StyledPagaLogo = styled(SVG)`
    max-height: 100px !important;

    ${breakpoint("lg")`
        max-height: 150px !important;
    `}
`;
const StyledHdwLogo = styled(SVG)`
max-height: 50px !important;

${breakpoint("lg")`
    max-height: 60px !important;
`}
`;

const StyledHdWeeklyLogo = styled(SVG)`
    max-height: 60px !important;

    ${breakpoint("lg")`
        max-height: 70px !important;
    `}
`;
const StyledMarkprintLogo = styled(SVG)`
    max-height: 25px !important;

    ${breakpoint("lg")`
        max-height: 32px !important;
    `}
`;
const StyledLsbLogo = styled(SVG)`
    max-height: 50px !important;

    ${breakpoint("lg")`
        max-height: 70px !important;
    `}
`;
const StyledBastaLogo = styled(SVG)`
    max-height: 30px !important;

    ${breakpoint("lg")`
        max-height: 50px !important;
    `}
`;
const StyledOutdoorFinlandLogo = styled(SVG)`
    max-height: 100px !important;

    ${breakpoint("lg")`
        max-height: 180px !important;
    `}
`;
const StyledDeedCreativeLogo = styled(SVG)`
    max-height: 80px !important;

    ${breakpoint("lg")`
        max-height: 140px !important;
    `}
`;
const StyledALehdetLogo = styled(SVG)`
    max-height: 80px !important;

    ${breakpoint("lg")`
        max-height: 140px !important;
    `}
`;

const StyledEeroAarnioLogo = styled(SVG)`
    max-height: 100px !important;

    ${breakpoint("lg")`
        max-height: 150px !important;
    `}
`;

const StyledGrafiaLogo = styled.div`
    width: 80px;
    margin: 0 auto;

    svg {
        width: 70%;
        height: auto;
        * {
            fill: var(--color-yellowish);
            ${breakpoint("md")`
                fill: var(--color-orange);
            `}
        }
    }

    ${breakpoint("lg")`
        width: 100px;
        margin: 0 auto;
        padding-top: 5px;
    `}
`;

const StyledAlignRight = styled.div`
    text-align: left;
    ${breakpoint("md")`
        text-align: right;
    `}
`;


export default injectIntl(Footer);
