import React, { componentDidMount } from 'react';
import Header from "./header";
import SideBarRight from "./sidebar-right";
import styled, { ThemeProvider } from "styled-components";
import breakpoint from "styled-components-breakpoint";
import SizeAware from "../components/size-aware";
import { Parallax, ParallaxLayer } from 'react-spring/renderprops-addons'

import "../styles/layout.css";

import Footer from "../components/footer";


export const theme = {
  breakpoints: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200
  },
  spacing: {
    xs: 15,
    sm: 30,
    md: 30,
    lg: 40,
    xl: 60
  }
};


const TopRibbon = styled.div`
  height: 22px;
  width: 5000px;
  background-color: var(--color-white);
  padding: 4px 0;
  text-transform: uppercase;
  border-bottom: 2px solid var(--color-black);
  overflow: hidden;
  font-size: 16px;
  display: flex;
  ${breakpoint("lg")`
    height: 30px;
    padding: 7px 0;
    font-size: 20px;
  `}
`;

// the top ribbon text needs a fixed width for the animation loop
const TopRibbonText = styled.div`
  width: 300px;
  display: flex;
  justify-content: space-around;
  // animation: slide-left 5s linear;
  // animation-iteration-count: infinite;
  span {
    display: block;
  }
  ${breakpoint("lg")`
    width: 400px;
  `}
   transition-property: transform;
   transition-duration: 400ms;
   transition-delay: 0;
   transition-timing-function: easeOutCubic;
  -webkit-transform: translateX(${props => props.scrollPos ? `${props.scrollPos}px` : `0px`});
          transform: translateX(${props => props.scrollPos ? `${props.scrollPos}px` : `0px`});

  @keyframes slide-left {
    from {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
    }
    to {
      -webkit-transform: translateX(-299px);
              transform: translateX(-299px);
    }
  }
  ${breakpoint("lg")`
    @keyframes slide-left {
      to {
        -webkit-transform: translateX(-399px);
                transform: translateX(-399px);
      }
    }
  `}
`;

const StyledPanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 250px;
  padding: 0;
  justify-content: space-around;
  flex-wrap: nowrap;
  align-items: stretch;
  overflow: hidden;
  height: 100vh;
  height: -webkit-fill-available;
  body[data-programme="true"][data-bg-color="blue"] & { background-color: var(--color-blue); }
  body[data-programme="true"][data-bg-color="pink"] & { background-color: var(--color-pink); }
  body[data-programme="true"][data-bg-color="red"] & { background-color: var(--color-red); }
  body[data-programme="true"][data-bg-color="yellow"] & { background-color: var(--color-yellow); }
  body[data-programme="true"][data-bg-color="purple"] & { background-color: var(--color-purple); }
  body[data-programme="true"][data-bg-color="orange"] & { background-color: var(--color-orange); }
  body[data-programme="true"][data-bg-color="green"] & { background-color: var(--color-green); }
  .content-height {
    height: 100%;
    max-height: calc((100vh - ${props => props.headerHeight ? `${props.headerHeight}px` : `0px`}) + 2px);
  }

  .content-height-minus {
    height: 100%;
    max-height: calc((100vh - ${props => props.headerHeight ? `${props.headerHeight}px` : `0px`}) - 45px + 2px);
  }

  .content-height-new {
    height: inherit;
  }

  .content-height-min {
    height: 100%;
    min-height: calc((100vh - ${props => props.headerHeight ? `${props.headerHeight}px` : `0px`}) + 2px);
  }

  background-color: var(--color-${props => props.bgColor ? `${props.bgColor}` : ''});
`;

const StyledPanelTop = styled.div`
  flex: none;
  border-bottom: 1px solid var(--color-orange);
  ${breakpoint("lg")`
    overflow: hidden;
    height: 60px;
  `}
`;



export const FixedImage = styled.div`
  display: none;

  ${props => props.overlayColor && `
    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: var(--color-${props.overlayColor});
      mix-blend-mode: screen;
    }
  `}

  ${breakpoint("md")`
    display: block;
    position: fixed;
    z-index: 1;
    left: 0;
    bottom: 0;
    width: 50%;
    height: calc(100vh - 80px);
    background-image: url(${props => props.fixedImageSrc});
    background-size: cover;
    background-position: 50% 50%;
    ${props => props.bgColor && 'background-color: var(--color-'+props.bgColor+'); background-blend-mode: luminosity;'}
  `}
  ${breakpoint("lg")`
    height: calc(100vh - 106px);
  `}
`;

const StyledPanelContent = styled.div`
  display: flex;
  position: relative;
  flex: 1 1 auto;
  overflow: hidden;
  height: calc((100vh - ${props => props.headerHeight ? `${props.headerHeight}px` : `0px`}) + 18px);

  // body[data-programme*="true"] &,
  // body[data-programme*="true"] & {
  //   background-color: var(--color-yellow);
  // }
`;

const StyledPanelContentBlock = styled.div``;

const StyledPanelContentContainer = styled.div`
`;


const Layout = ({ children, onHideNav, onShowNav, showNav, siteTitle, ...props }) => {
  // console.log(location)
  // console.log(prevLocation)
  return (
    <ThemeProvider theme={theme}>
      <>
        <LayoutPanelsComponent
          siteTitle={siteTitle}
          onHideNav={onHideNav}
          onShowNav={onShowNav}
          showNav={showNav}
          children={children}
          {...props}
        />
      </>
    </ThemeProvider>
  )
};

class LayoutPanelsComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      headerHeight: 0,
      scrollPos: -400
    }
  }

  onSize = size => {
    // console.log('Header has a height of', size.height);
    this.setState({
      headerHeight: size.height
    })
  }

  // listen to parallax scrolling
  componentDidMount = () => {
    // document.querySelector('#scroller .parallax').addEventListener('scroll', ()=>{
    //   this.setState({
    //     scrollPos: - ((document.querySelector('#scroller .parallax').scrollTop/5)+400)
    //   });
    // });
  };

  render() {
    return (
      <StyledPanelContainer headerHeight={this.state.headerHeight}>
        <SizeAware onSize={this.onSize}>
          {/*
          <TopRibbon>
            <TopRibbonText scrollPos={this.state.scrollPos}><span class="title">Huiput Creative Festival</span><span class="date">11.5.2022</span></TopRibbonText>
            <TopRibbonText scrollPos={this.state.scrollPos}><span class="title">Huiput Creative Festival</span><span class="date">11.5.2022</span></TopRibbonText>
            <TopRibbonText scrollPos={this.state.scrollPos}><span class="title">Huiput Creative Festival</span><span class="date">11.5.2022</span></TopRibbonText>
            <TopRibbonText scrollPos={this.state.scrollPos}><span class="title">Huiput Creative Festival</span><span class="date">11.5.2022</span></TopRibbonText>
            <TopRibbonText scrollPos={this.state.scrollPos}><span class="title">Huiput Creative Festival</span><span class="date">11.5.2022</span></TopRibbonText>
            <TopRibbonText scrollPos={this.state.scrollPos}><span class="title">Huiput Creative Festival</span><span class="date">11.5.2022</span></TopRibbonText>
            <TopRibbonText scrollPos={this.state.scrollPos}><span class="title">Huiput Creative Festival</span><span class="date">11.5.2022</span></TopRibbonText>
          </TopRibbon>
          */}
          <StyledPanelTop>
            <Header
              siteTitle={this.props.siteTitle}
              onHideNav={this.props.onHideNav}
              onShowNav={this.props.onShowNav}
              showNav={this.props.showNav}
            />
          </StyledPanelTop>
        </SizeAware>
        <StyledPanelContent headerHeight={this.state.headerHeight} id="scroller">
          <Parallax className="parallax" id="scrollable" scrolling={true} vertical ref={ref => (this.parallax = ref)}>
            <StyledPanelContentBlock>
              <StyledPanelContentContainer>
                {this.props.children}
              </StyledPanelContentContainer>
              <Footer />
            </StyledPanelContentBlock>
          </Parallax>
        </StyledPanelContent>
      </StyledPanelContainer>
    );
  }
}

export default Layout;
